
import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

export default function TableCustom({ items, actions }) {
    const [customers, setCustomers] = useState(items);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    useEffect(() => {
        setCustomers(items)
        setLoading(false)
    }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="buscar..." />
                </IconField>
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return actions(rowData);
    };

    const header = renderHeader();

    return (
        <div className="card">
            {customers && <DataTable value={customers} paginator rows={10} dataKey="id" filters={filters} filterDisplay="row" loading={loading}
                globalFilterFields={['id', 'nome', 'email', 'cpf', 'adimplente']} header={header} emptyMessage="Nenhum registro encontrado">
                <Column header="#" sortable field="id" />
                <Column header="Nome" sortable field="nome" style={{ minWidth: '12rem' }} />
                <Column header="Email" sortable field="email" style={{ minWidth: '12rem' }} />
                <Column header="CPF" sortable field="cpf" style={{ minWidth: '12rem' }} />
                <Column header="Ativo" sortable field="adimplente" style={{ minWidth: '12rem' }} />
                <Column header="Ação" body={actionBodyTemplate} />
            </DataTable>}
        </div>
    );
}
